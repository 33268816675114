@use "./normalize";
@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;900&display=swap');

::-webkit-scrollbar-track
{
	border: 4px solid $background-scroll-white-color;
	background-color: $background-scroll-normal-grey-color;
}

::-webkit-scrollbar
{
	width: 8px;
	background-color: $background-scroll-light-grey-color;
}

::-webkit-scrollbar-thumb
{
	background-color: $background-action-color;
	border-radius: 10px;
}
