.manage-companies-table {
    font-family: 'Poppins';

    table {
        border-spacing: 0px !important;
    }

    td {
        border-right: 1px solid rgba(224, 224, 224, 1);
        border-top: 1px solid rgba(224, 224, 224, 1);
        font-family: 'Poppins';
        font-size: 16px;
    }

    td:last-child {
        border-right: none;
    }

    td:first-child {
        border-left: none;
    }

    th {
        border-right: 1px solid rgba(224, 224, 224, 1);
        font-family: 'Poppins';
        font-size: 18px;
        font-weight: 700;
        padding: 20px;
    }

    th:last-child {
        border-right: none;
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }
}