pre {
  code {
    display: block;
    margin: 1rem;
    padding: 1rem;
    background-color: rgb(0, 30, 60);
    border: 1px solid #999;
    border-radius: 4px;
    overflow: auto;
    color: aliceblue;
  }
}
