// @link https://www.figma.com/file/NEkxr0jcaiX1LKu4if6FOE/SaveONexpress-(DS)
// IMPORTANT : Thoses values have to be manually syncronised from theme

// Example :
// Content neutral
// $color-content-neutral-primary: #383838;
// $color-content-neutral-secondary: #757575;

// @toto : delete those when we will get rid of all CSS
$primary-color: #506ec1;
$secondary-color: red;
$background-action-color: #095797;
$background-scroll-white-color: white;
$background-scroll-light-grey-color: #dfe6e9;
$background-scroll-normal-grey-color: #b2bec3;
// $default-color: "#FAFAFA"; // not use
:export {
  primary-color: $primary-color;
  secondary-color: $secondary-color;
  background-action-color: $background-action-color;
  background-scroll-white-color: $background-scroll-white-color;
  background-scroll-lightgrey-color: $background-scroll-light-grey-color;
  background-scroll-normalgrey-color: $background-scroll-normal-grey-color;
  // default-color: "#FAFAFA"; // not use
}
